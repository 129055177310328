import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DATA_LOADING_LEVEL } from "../constants";
import { formData, MainData as newData } from "../data/appData";
import {
  SECTION_ID,
  API,
  DataSection,
  FormData,
  DataNode,
  MainData,
} from "../data/types";
import { languages } from "../data/constants";

export const pdfText3Flat = `<p>Beim zum beurteilenden Objekt handelt es sich um ein Flachdach mit folgendem Aufbau:</p>`;

export const pdfText3Pitched = `<p>Beim zum beurteilenden Objekt handelt es sich um ein Steildach mit folgendem Aufbau:</p>`;

interface SkipStep {
  steps: number[];
  rule: string;
  level: number;
}

export enum RoofType {
  FLAT = "Flat",
  PITCHED = "Pitched",
}

export interface PdfFormData {
  answers: {
    [key: string]: {
      q: string;
      a: string;
      w?: string | undefined;
    }[];
  };
  formDataAnswers: {
    [key: string]: string;
  };
  pdfContent: {
    pdfText1: string;
    pdfText2: string;
    pdfText3: string;
    pdfText4: string;
    pdfText5: string;
  };
  roofType: RoofType;
}

export interface CheckedRadio {
  id: string;
  level: number;
  value: string;
}

export enum ErrorType {
  "FAILED_REQUEST" = 1,
  "NO_VALID_PARAMETERS",
}

export type QA = { question: string; answer: string };

export interface AppState {
  data: DataSection[];
  currentLevel: number;
  selection: string[];
  lastLevelReached: boolean;
  roofChosen: boolean;
  formData: FormData;
  formTimestamp: number;
  password: string;
  skipSteps: SkipStep[];
  checkedRadios: CheckedRadio[];
  language: string;
  parametersFetching: boolean;
  parametersFetched: boolean;
  parameterFetchError: ErrorType | undefined;
  formSubmitting: boolean;
  formSubmitted: boolean;
  formSubmitError: ErrorType | undefined;
  backendValues: { [key: string]: { [key: string]: boolean } };
  pdfFormData: PdfFormData | undefined;
  qa: QA[];
}

export const initialState: AppState = {
  data: [newData[0]],
  currentLevel: 1,
  formTimestamp: 0,
  lastLevelReached: false,
  roofChosen: false,
  selection: [] as string[],
  formData,
  password: "",
  skipSteps: [],
  checkedRadios: [],
  language: languages[0],
  parametersFetched: false,
  parametersFetching: false,
  parameterFetchError: undefined,
  formSubmitted: false,
  formSubmitting: false,
  formSubmitError: undefined,
  backendValues: {},
  pdfFormData: undefined,
  qa: [],
};

export const debugState = (st: any) => JSON.parse(JSON.stringify(st));

const allNodesSelected = (nodes: DataNode[]) => !nodes.some((n) => !n.selected);

const isDeadEnd = (nodes: DataNode[]) =>
  !!nodes
    .filter((n) => n.selected)
    .some((sn) =>
      sn.childNodes?.some((c) => sn.selectValue === c.value && c.end)
    );

const levelComplete = (nodes: DataNode[], mustSelectAll: boolean) =>
  (mustSelectAll && allNodesSelected(nodes)) ||
  (!mustSelectAll && nodes.some((e) => e.selected));

const addLevel = (
  nextLevel: number,
  nodes: DataNode[],
  skipSteps: SkipStep[],
  levelLength: number,
  mustSelectAll: boolean
) => {
  for (let i = 0; i < skipSteps.length; i++) {
    const skipStep = skipSteps[i];
    if (
      levelComplete(nodes, mustSelectAll) &&
      nodes.some(
        (n) => n.value === skipStep.rule || n.selectValue === skipStep.rule
      ) &&
      skipStep.steps.includes(nextLevel)
    ) {
      nextLevel = Math.min(levelLength, nextLevel + 1);
    }
  }
  return nextLevel;
};

const deselectNodes = (nodes: DataNode[]) => {
  return nodes.map((n) => {
    if (n.childNodes) {
      return {
        ...n,
        selected: false,
        ...(n.value && { value: "" }),
      };
    }
    return {
      ...n,
      selected: false,
    };
  });
};

const lastLevelShouldBeSkipped = (
  skipSteps: SkipStep[],
  currentLevel: number,
  data: MainData
) => {
  const stepToSkip = skipSteps.find((st) => {
    return st.steps.includes(currentLevel);
  });
  if (!stepToSkip) return false;
  return !!Object.keys(data).find(
    (k: string) =>
      !!data[parseInt(k, 10)].nodes.find(
        (n) =>
          (n.value === stepToSkip.rule || n.selectValue === stepToSkip.rule) &&
          n.selected &&
          parseInt(k, 10) === stepToSkip.level
      )
  );
};

const setAvailableDaaMmValues = (
  nodes: DataNode[],
  backendData: { [key: string]: { [key: string]: boolean } }
) => {
  return nodes.map((n) => {
    if (n.id === "9_1" || n.id === "pr_10_1") {
      return {
        ...n,
        childNodes: n.childNodes?.map((cn) => {
          const c_key = Object.keys(backendData).find((d) => {
            return n.id === "pr_10_1"
              ? cn.value === d
              : cn.value.includes(String(d).replace(/[^0-9]/g, ""));
          });
          if (!c_key) return cn;
          const beValues = backendData[c_key];
          return {
            ...cn,
            disabled: !Object.values(beValues).some((e) => e === true),
          };
        }),
      };
    } else {
      return n;
    }
  });
};

const setAvailableAngles = (
  nodes: DataNode[],
  backendData: { [key: string]: { [key: string]: boolean } }
) => {
  return nodes.map((n) => {
    if (n.id === "pr_11_1") {
      return {
        ...n,
        childNodes: n.childNodes?.map((cn) => {
          const c_key = Object.keys(backendData).find((d) => {
            return cn.value.includes(String(d));
          });
          if (!c_key) return cn;
          const beValues = backendData[c_key];
          return {
            ...cn,
            disabled: !Object.values(beValues).some((e) => e === true),
          };
        }),
      };
    } else {
      return n;
    }
  });
};

const setAvailableLambdas = (
  nodes: DataNode[],
  backendData: { [key: string]: { [key: string]: boolean } }
) => {
  const currentDAAValue = nodes[0]?.selectValue;
  if (!currentDAAValue) return nodes;
  const key = Object.keys(backendData).find((d) => {
    if (nodes[0].id === "pr_10_1") {
      return (
        currentDAAValue === String(d) ||
        (currentDAAValue.includes("≥") && currentDAAValue.includes(String(d)))
      );
    } else {
      return currentDAAValue.includes(String(d).replace(/[^0-9]/g, ""));
    }
  });
  if (!key) return nodes;
  const dataToUse = backendData[key];
  const newNodes = nodes.map((n, i) => {
    if (i === 1) {
      return {
        ...n,
        childNodes: n.childNodes?.map((cn) => {
          return {
            ...cn,
            disabled:
              dataToUse[cn.value] !== undefined ? !dataToUse[cn.value] : false,
          };
        }),
      };
    } else {
      return n;
    }
  });
  return newNodes;
};

const setAvailableAngleValues = (
  nodes: DataNode[],
  backendData: { [key: string]: { [key: string]: boolean } }
) => {
  const currentAngleValue = nodes[0]?.selectValue;
  if (!currentAngleValue) return nodes;
  const key = Object.keys(backendData).find((d) => {
    return currentAngleValue.includes(String(d));
  });
  if (!key) return nodes;
  const dataToUse = backendData[key];
  const newNodes = nodes.map((n, i) => {
    if (i === 1) {
      return {
        ...n,
        childNodes: n.childNodes?.map((cn) => {
          return {
            ...cn,
            disabled:
              dataToUse[cn.value] !== undefined ? !dataToUse[cn.value] : false,
          };
        }),
      };
    } else {
      return n;
    }
  });
  return newNodes;
};

const addSkipSteps = (
  skipSteps: SkipStep[],
  newSkipSteps: number[],
  value: string,
  level: number
) => {
  if (
    !skipSteps.find((skipSt) => value === skipSt.rule && level === skipSt.level)
  ) {
    return [
      ...skipSteps,
      {
        steps: newSkipSteps ?? [],
        rule: value,
        level,
      },
    ];
  }
  return skipSteps;
};

export const appDataSlice = createSlice({
  name: "appData",
  initialState,
  reducers: {
    setParametersFetching: (state, action: PayloadAction<boolean>) => {
      state.parametersFetching = action.payload;
    },
    setParametersFetched: (state, action: PayloadAction<boolean>) => {
      state.parametersFetched = action.payload;
    },
    setParametersFetchError: (
      state,
      action: PayloadAction<ErrorType | undefined>
    ) => {
      state.parameterFetchError = action.payload;
    },
    setFormSubmitting: (state, action: PayloadAction<boolean>) => {
      state.formSubmitting = action.payload;
    },
    setformSubmitted: (state, action: PayloadAction<boolean>) => {
      state.formSubmitted = action.payload;
    },
    setFormSubmitError: (
      state,
      action: PayloadAction<ErrorType | undefined>
    ) => {
      state.formSubmitError = action.payload;
    },
    setCurrentLevel: (state, action: PayloadAction<number>) => {
      state.currentLevel += action.payload;
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    deselectAtLevel: (state, action: PayloadAction<{ level: number }>) => {
      const l = action.payload.level;
      state.data[l].nodes = deselectNodes(state.data[l].nodes);
    },
    setSelectionAtCurrentLevel: (
      state,
      action: PayloadAction<{ level: number; id: string; value: string }>
    ) => {
      const levelLength = Object.keys(state.data).length;
      const totalLevelLength = Object.keys(state.data).length + 1;
      const { id, level, value } = action.payload;
      const { multipleChoice, mustSelectAll } = state.data[level];
      let hasEnd = false;

      state.data[level].nodes = state.data[level].nodes.map((e) => {
        const selectValueObj =
          e.childNodes && e.childNodes.find((c) => c.id === value);
        const selectValue = selectValueObj?.title ?? undefined;
        if (e.id === id || e.id === selectValueObj?.id) {
          if (e.end || selectValueObj?.end) {
            hasEnd = true;
          }
          if (e.skipSteps || selectValueObj?.skipSteps) {
            state.skipSteps = addSkipSteps(
              state.skipSteps,
              e.skipSteps ?? selectValueObj?.skipSteps ?? [],
              value ?? selectValue,
              level
            );
          }
          return {
            ...e,
            selected: true,
            value,
            ...(selectValue ? { selectValue } : {}),
          };
        }
        return { ...e, selected: multipleChoice ? e.selected : false };
      });

      for (let i = level + 1; i < totalLevelLength; i++) {
        state.data[i].nodes = deselectNodes(state.data[i].nodes);
        state.checkedRadios = state.checkedRadios.filter(
          (e) => e.level < level
        );
        state.skipSteps = state.skipSteps.filter(
          (skipSt) =>
            skipSt.level < level ||
            (skipSt.level === level && skipSt.rule === value)
        );
      }

      if (level + 1 < totalLevelLength && !hasEnd) {
        if (mustSelectAll) {
          if (allNodesSelected(state.data[level].nodes)) {
            state.currentLevel = addLevel(
              level + 1,
              state.data[level].nodes,
              state.skipSteps,
              levelLength,
              mustSelectAll
            );
          }
        } else {
          state.currentLevel = addLevel(
            level + 1,
            state.data[level].nodes,
            state.skipSteps,
            levelLength,
            false
          );
        }
      }

      if (level === DATA_LOADING_LEVEL - 1) {
        state.parametersFetched = false;
      }

      if (level === DATA_LOADING_LEVEL || level === DATA_LOADING_LEVEL - 1) {
        state.data[DATA_LOADING_LEVEL].nodes = state.data[
          DATA_LOADING_LEVEL
        ].nodes.map((n) => {
          if (n.id === "9_2") {
            n.disabled = !state.data[level].nodes.find((n) => n.id === "9_1")
              ?.selected;
            return n;
          }
          return n;
        });
      }

      if (
        hasEnd ||
        state.data[level].nodes.some(
          (nn) =>
            nn.selected &&
            nn.childNodes?.find((nnn) => nnn.id === nn.value && nnn.end)
        )
      ) {
        state.currentLevel = level;
      }

      state.lastLevelReached =
        state.currentLevel === Object.keys(state.data).length &&
        (levelComplete(
          state.data[state.currentLevel].nodes,
          state.data[state.currentLevel].mustSelectAll ?? false
        ) ||
          lastLevelShouldBeSkipped(
            state.skipSteps,
            state.currentLevel,
            state.data
          ));

      state.roofChosen = !!Object.keys(state.data).find(
        (e) =>
          state.data[parseInt(e, 10)].title === "Type of roof:" &&
          state.data[parseInt(e, 10)].nodes.find((n) => n.selected)
      );

      if (id === "9_1") {
        state.data[level].nodes = state.data[level].nodes.map((n) => {
          if (n.id === "9_2") {
            return { ...n, selectValue: undefined, value: "" };
          }
          return n;
        });
        state.currentLevel = DATA_LOADING_LEVEL;
        state.lastLevelReached = false;
      }

      if (level === DATA_LOADING_LEVEL) {
        state.data[DATA_LOADING_LEVEL].nodes = setAvailableLambdas(
          state.data[DATA_LOADING_LEVEL].nodes,
          state.backendValues
        );
      }
    },
    setNextSelection: (
      state,
      action: PayloadAction<{ level: number; id: string; value: string }>
    ) => {
      state.lastLevelReached = false;
      const { id, level, value } = action.payload;
      const { multipleChoice, mustSelectAll } = state.data[level];
      let nextSection: SECTION_ID | undefined;
      state.data[level].nodes = state.data[level].nodes.map((e) => {
        const selectValueObj =
          e.childNodes && e.childNodes.find((c) => c.id === value);
        const selectValue = selectValueObj?.title ?? undefined;
        if (e.id === id || e.id === selectValueObj?.id) {
          // if (e.end || selectValueObj?.end) {
          //   hasEnd = true;
          // }
          nextSection = e.nextSection;
          return {
            ...e,
            selected: true,
            value,
            ...(selectValue ? { selectValue } : {}),
          };
        }
        // if (e.selected) {
        //   nextSection = e.nextSection;
        // }
        return { ...e, selected: multipleChoice ? e.selected : false };
      });
      let i = state.data.length - 1;

      if (
        state.data[level].api &&
        state.data[level].sequential &&
        state.data[level].nodes[0].id === id
      ) {
        state.data[level].nodes = state.data[level].nodes.map((n, i) => {
          if (i > 0) {
            return { ...n, selectValue: undefined, value: "", selected: false };
          }
          return n;
        });
        state.lastLevelReached = false;
      }

      while (state.data[i].id !== state.data[level].id) {
        state.data.pop();
        i--;
      }

      const nextSectionData = (
        nextSection
          ? newData.find((d) => d.id === nextSection)
          : newData.find((d) => d.id === state.data[level].nextSection)
      ) as DataSection | undefined;
      if (nextSection) {
        if (nextSectionData) {
          state.data.push(nextSectionData);
        }
        if (nextSection === SECTION_ID.FORM) {
          state.lastLevelReached = true;
        } else {
          state.lastLevelReached = false;
        }
      } else if (
        mustSelectAll &&
        allNodesSelected(state.data[level].nodes) &&
        !isDeadEnd(state.data[level].nodes) &&
        // !state.data[level].nodes.some((n) => n.end) &&
        state.data[level].nextSection
      ) {
        nextSectionData && state.data.push(nextSectionData);
        if (state.data[level].nextSection === SECTION_ID.FORM) {
          state.lastLevelReached = true;
        } else {
          state.lastLevelReached = false;
        }
      }
      state.currentLevel = state.data.length - 1;
      // console.log("current state", debugState(state.data));
      // console.log("lastLevelReached", state.lastLevelReached);

      // if (
      //   !!state.data[level + 1]?.api &&
      //   state.data[level + 1].paramsFetched !== undefined
      // ) {
      //   state.data[level + 1].paramsFetched = false;
      // }

      if (!!state.data[level]?.api || !!state.data[level + 1]?.api) {
        state.data[level].nodes = state.data[level].nodes.map((n, i) => {
          if (i > 0 && !!state.data[level]?.api) {
            n.disabled = !state.data[level].nodes[0]?.selected;
            return n;
          }
          return n;
        });
      }
      if (!!state.data[level]?.api && state.data[level].nodes[0].selected) {
        if (
          state.data[level]?.api === API.FLAT_ROOF ||
          state.data[level]?.api === API.PITCHED_ROOF_OUTSIDE_MAJCOAT
        ) {
          state.data[level].nodes = setAvailableLambdas(
            state.data[level].nodes,
            state.backendValues
          );
        } else if (state.data[level]?.api === API.PITCHED_ROOF) {
          state.data[level].nodes = setAvailableAngleValues(
            state.data[level].nodes,
            state.backendValues
          );
        }
      }
    },
    setSectionParametersFetched: (
      state,
      action: PayloadAction<{ level: number; fetched: boolean }>
    ) => {
      state.data[action.payload.level].paramsFetched = action.payload.fetched;
    },
    setFormFieldValue: (
      state,
      action: PayloadAction<{ id: string; value: string }>
    ) => {
      const { id, value } = action.payload;
      state.formData.fields = state.formData.fields.map((f) => {
        if (f.id === id) return { ...f, value };
        return f;
      });
    },
    setCheckedRadios: (
      state,
      action: PayloadAction<{ id: string; level: number }>
    ) => {
      const { id, level } = action.payload;
      state.checkedRadios.push({ id, level, value: "" });
    },
    modifyCheckedRadio: (
      state,
      action: PayloadAction<{ id: string; level: number; value: string }>
    ) => {
      const { id, level, value } = action.payload;
      state.checkedRadios = state.checkedRadios.map((e) => {
        if (e.id === id && e.level === level) {
          return { ...e, value };
        }
        return e;
      });
    },
    uncheckAllRadios: (state) => {
      state.checkedRadios = [];
    },
    setBackendValues: (
      state,
      action: PayloadAction<{
        data: { [key: string]: { [key: string]: boolean } };
        api: API;
      }>
    ) => {
      const { data, api } = action.payload;
      state.backendValues = data;
      const idx = state.data.findIndex((d) => d.api === action.payload.api);
      if (api === API.FLAT_ROOF || api === API.PITCHED_ROOF_OUTSIDE_MAJCOAT) {
        state.data[idx].nodes = setAvailableDaaMmValues(
          state.data[idx].nodes,
          state.backendValues
        );
      } else if (api === API.PITCHED_ROOF) {
        state.data[idx].nodes = setAvailableAngles(
          state.data[idx].nodes,
          state.backendValues
        );
      }
    },
    setFormTimestamp: (state, action: PayloadAction<number>) => {
      state.formTimestamp = action.payload;
    },
    setPdfFormDataToSend: (state, action: PayloadAction<PdfFormData>) => {
      state.pdfFormData = action.payload;
    },
    setQA: (state, action: PayloadAction<QA[]>) => {
      state.qa = action.payload;
    },
    resetAllState: (state) => {
      return { ...initialState, password: state.password };
    },
  },
});

export const {
  setCurrentLevel,
  setSelectionAtCurrentLevel,
  setLanguage,
  setFormFieldValue,
  setPassword,
  setCheckedRadios,
  modifyCheckedRadio,
  uncheckAllRadios,
  deselectAtLevel,
  setParametersFetched,
  setParametersFetching,
  setParametersFetchError,
  setBackendValues,
  setFormTimestamp,
  setFormSubmitError,
  setFormSubmitting,
  setformSubmitted,
  setPdfFormDataToSend,
  resetAllState,
  setQA,
  setNextSelection,
  setSectionParametersFetched,
} = appDataSlice.actions;

export default appDataSlice.reducer;
