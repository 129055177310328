import { API, BUTTON_TYPE, SECTION_ID } from "../../types";
import pitchedRoof_barrier from "../../../assets/pitchedRoof_majcoat.svg";
import { notSupportedMessage } from "../../constants";

export const pitchedRoofVaporBarrierSections = [
  {
    id: SECTION_ID.PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS_BARRIER,
    title:
      "Type of insulation material between the rafters (full rafter insulation):",
    divider: true,
    nr: 1,
    highlightNode: {
      top: "165px",
      left: "",
      width: "100%",
      height: "110px",
    },
    visualNode: {
      img: pitchedRoof_barrier,
      label: "1",
      top: "205px",
      wrapperLeft: "0%",
      left: "360px",
      imgTop: "5%",
      width: "100%",
    },
    nodes: [
      {
        id: "pr_b_5_1",
        title: "Cellulose",
        value: "Cellulose",
        nextSection: SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES_BARRIER,
      },
      {
        id: "pr_b_5_2",
        title: "Mineral wool",
        value: "Mineral wool",
        nextSection: SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES_BARRIER,
      },
      {
        id: "pr_b_5_3",
        title: "Wood Fibre",
        value: "Wood Fibre",
        nextSection: SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES_BARRIER,
      },
      { id: "pr_b_5_4", title: "Other", value: "Other", end: true },
      {
        id: "pr_b_5_6",
        title: "Hemp / sheep wool",
        value: "Hemp / sheep wool",
        nextSection: SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES_BARRIER,
      },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "pr_b_5_4",
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_MATERIAL_PROPERTIES_BARRIER,
    title:
      "What material properties does the intermediate rafter insulation have?",
    // nextSection: SECTION_ID.WOODEN_SUPPORT_PANEL,
    highlightNode: {
      top: "90px",
      left: "",
      width: "100%",
      height: "152px",
    },
    nr: 1,
    visualNode: {
      img: pitchedRoof_barrier,
      label: "1",
      top: "140px",
      left: "120px",
      imgTop: "10%",
    },
    multipleChoice: true,
    mustSelectAll: true,
    nextSection: SECTION_ID.PITCHED_ROOF_WOODEN_SUPPORT_PANEL_BARRIER,
    nodes: [
      {
        id: "pr_b_2_1",
        title: "Insulation layer thickness (m):",
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        childNodes: [
          {
            id: "pr_b_2_1_1",
            title: "0.12",
            value: "0.12",
            parent: "pr_b_2_1",
          },
          {
            id: "pr_b_2_1_2",
            title: "0.13",
            value: "0.13",
            parent: "pr_b_2_1",
          },
          {
            id: "pr_b_2_1_3",
            title: "0.14",
            value: "0.14",
            parent: "pr_b_2_1",
          },
          {
            id: "pr_b_2_1_4",
            title: "0.15",
            value: "0.15",
            parent: "pr_b_2_1",
          },
          {
            id: "pr_b_2_1_5",
            title: "0.16",
            value: "0.16",
            parent: "pr_b_2_1",
          },
          {
            id: "pr_b_2_1_6",
            title: "0.17",
            value: "0.17",
            parent: "pr_b_2_1",
          },
          {
            id: "pr_b_2_1_7",
            title: "0.18",
            value: "0.18",
            parent: "pr_b_2_1",
          },
          {
            id: "pr_b_2_1_8",
            title: "0.19",
            value: "0.19",
            parent: "pr_b_2_1",
          },
          {
            id: "pr_b_2_1_9",
            title: "0.20",
            value: "0.20",
            parent: "pr_b_2_1",
          },
          {
            id: "pr_b_2_1_10",
            title: "0.21",
            value: "0.21",
            parent: "pr_b_2_1",
          },
          {
            id: "pr_b_2_1_11",
            title: "0.22",
            value: "0.22",
            parent: "pr_b_2_1",
          },
          {
            id: "pr_b_2_1_12",
            title: "0.23",
            value: "0.23",
            parent: "pr_b_2_1",
          },
          {
            id: "pr_b_2_1_13",
            title: "0.24",
            value: "0.24",
            parent: "pr_b_2_1",
          },
          {
            id: "pr_b_2_1_14",
            title: "other",
            value: "other",
            parent: "pr_b_2_1",
            end: true,
          },
        ],
      },
      {
        id: "pr_b_2_2",
        title: "Lambda value λ(w/mK):",
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        childNodes: [
          {
            id: "pr_b_2_2_1",
            title: "0.45",
            value: "0.45",
            parent: "pr_b_2_2",
          },
          {
            id: "pr_b_2_2_2",
            title: "0.42",
            value: "0.42",
            parent: "pr_b_2_2",
          },
          {
            id: "pr_b_2_2_3",
            title: "0.40",
            value: "0.40",
            parent: "pr_b_2_2",
          },
          {
            id: "pr_b_2_2_4",
            title: "0.39",
            value: "0.39",
            parent: "pr_b_2_2",
          },
          {
            id: "pr_b_2_2_5",
            title: "0.38",
            value: "0.38",
            parent: "pr_b_2_2",
          },
          {
            id: "pr_b_2_2_6",
            title: "0.35",
            value: "0.35",
            parent: "pr_b_2_2",
          },
          {
            id: "pr_b_2_2_7",
            title: "0.32",
            value: "0.32",
            parent: "pr_b_2_2",
          },
        ],
      },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "pr_b_2_1_14",
      },
    ],
  },
  {
    title: "Is there a wooden support panel for the over-insulation?",
    id: SECTION_ID.PITCHED_ROOF_WOODEN_SUPPORT_PANEL_BARRIER,
    nr: 2,
    divider: true,
    highlightNode: {
      top: "160px",
      left: "",
      width: "100%",
      height: "15px",
    },
    visualNode: {
      img: pitchedRoof_barrier,
      label: "2",
      top: "150px",
      wrapperLeft: "0%",
      left: "140px",
      imgTop: "5%",
      width: "100%",
    },
    nodes: [
      {
        id: "pr_b_7_1",
        title: "Yes",
        value: "Yes",
        buttonType: BUTTON_TYPE.SQUARE,
        nextSection: SECTION_ID.PITCHED_ROOF_INSULATION_OVER_RAFTERS_BARRIER,
      },
      {
        id: "pr_b_7_2",
        title: "No",
        value: "No",
        buttonType: BUTTON_TYPE.SQUARE,
        nextSection: SECTION_ID.PITCHED_ROOF_INSULATION_OVER_RAFTERS_BARRIER,
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_INSULATION_OVER_RAFTERS_BARRIER,
    title: "Type of insulation over the rafters",
    divider: true,
    nr: 3,
    highlightNode: {
      top: "86px",
      left: "",
      width: "100%",
      height: "75px",
    },
    visualNode: {
      img: pitchedRoof_barrier,
      label: "3",
      top: "105px",
      wrapperLeft: "0%",
      left: "290px",
      imgTop: "5%",
      width: "100%",
    },
    nodes: [
      {
        id: "pr_b_9_1",
        title: "Cellulose",
        value: "Cellulose",
        nextSection:
          SECTION_ID.PITCHED_ROOF_ON_ROOF_MATERIAL_PROPERTIES_BARRIER,
      },
      {
        id: "pr_b_9_3",
        title: "Wood Fibre",
        value: "Wood Fibre",
        nextSection:
          SECTION_ID.PITCHED_ROOF_ON_ROOF_MATERIAL_PROPERTIES_BARRIER,
      },
      {
        id: "pr_b_9_2",
        title: "Mineral wool",
        value: "Mineral wool",
        nextSection:
          SECTION_ID.PITCHED_ROOF_ON_ROOF_MATERIAL_PROPERTIES_BARRIER,
      },
      {
        id: "pr_b_9_4",
        title: "PUR / PIR / PH",
        value: "PUR / PIR / PH",
        nextSection:
          SECTION_ID.PITCHED_ROOF_ON_ROOF_MATERIAL_PROPERTIES_BARRIER,
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_ON_ROOF_MATERIAL_PROPERTIES_BARRIER,
    title: "What material properties does on-roof insulation have?",
    api: API.PITCHED_ROOF_OUTSIDE_BARRIER,
    paramsFetched: false,
    highlightNode: {
      top: "86px",
      left: "",
      width: "100%",
      height: "75px",
    },
    visualNode: {
      img: pitchedRoof_barrier,
      label: "3",
      top: "105px",
      wrapperLeft: "0%",
      left: "290px",
      imgTop: "5%",
      width: "100%",
    },
    divider: true,
    multipleChoice: true,
    mustSelectAll: true,
    sequential: true,
    nextSection: SECTION_ID.PITCHED_ROOF_VENTILATED_ROOFING_MATERIAL_BARRIER,
    nodes: [
      {
        id: "pr_b_10_1",
        title: "Insulation layer thickness (m):",
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        childNodes: [
          {
            id: "pr_b_10_1_1",
            title: "0.052",
            value: "0.052",
            parent: "pr_b_10_1",
          },
          {
            id: "pr_b_10_1_2",
            title: "0.060",
            value: "0.060",
            parent: "pr_b_10_1",
          },
          {
            id: "pr_b_10_1_3",
            title: "0.080",
            value: "0.080",
            parent: "pr_b_10_1",
          },
          {
            id: "pr_b_10_1_4",
            title: "0.100",
            value: "0.100",
            parent: "pr_b_10_1",
          },
          {
            id: "pr_b_10_1_5",
            title: "0.120",
            value: "0.120",
            parent: "pr_b_10_1",
          },
          {
            id: "pr_b_10_1_6",
            title: "0.140",
            value: "0.140",
            parent: "pr_b_10_1",
          },
          {
            id: "pr_b_10_1_7",
            title: "0.160",
            value: "0.160",
            parent: "pr_b_10_1",
          },
          {
            id: "pr_b_10_1_7",
            title: "0.180",
            value: "0.180",
            parent: "pr_b_10_1",
          },
          {
            id: "pr_b_10_1_8",
            title: "other",
            value: "other",
            parent: "pr_b_10_1",
            end: true,
          },
        ],
      },
      {
        id: "pr_b_10_2",
        title: "Lambda value λ(w/mK):",
        buttonType: BUTTON_TYPE.CHECKBOX,
        value: "",
        disabled: true,
        childNodes: [
          {
            id: "pr_b_10_2_1",
            title: "0.045",
            value: "0.045",
            parent: "pr_b_10_2",
          },
          {
            id: "pr_b_10_2_2",
            title: "0.042",
            value: "0.042",
            parent: "pr_b_10_2",
          },
          {
            id: "pr_b_10_2_3",
            title: "0.040",
            value: "0.040",
            parent: "pr_b_10_2",
          },
          {
            id: "pr_b_10_2_4",
            title: "0.039",
            value: "0.039",
            parent: "pr_b_10_2",
          },
          {
            id: "pr_b_10_2_5",
            title: "0.038",
            value: "0.038",
            parent: "pr_b_10_2",
          },
          {
            id: "pr_b_10_2_6",
            title: "0.035",
            value: "0.035",
            parent: "pr_b_10_2",
          },
          {
            id: "pr_b_10_2_7",
            title: "0.032",
            value: "0.032",
            parent: "pr_b_10_2",
          },
          {
            id: "pr_b_10_2_8",
            title: "0.029",
            value: "0.029",
            parent: "pr_b_10_2",
          },
          {
            id: "pr_b_10_2_9",
            title: "0.027",
            value: "0.027",
            parent: "pr_b_10_2",
          },
          {
            id: "pr_b_10_2_10",
            title: "0.025",
            value: "0.025",
            parent: "pr_b_10_2",
          },
          {
            id: "pr_b_10_2_11",
            title: "0.023",
            value: "0.023",
            parent: "pr_b_10_2",
          },
          {
            id: "pr_b_10_2_12",
            title: "0.022",
            value: "0.022",
            parent: "pr_b_10_2",
          },
        ],
      },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "pr_b_10_1_8",
      },
    ],
  },
  {
    id: SECTION_ID.PITCHED_ROOF_VENTILATED_ROOFING_MATERIAL_BARRIER,
    title: "Which ventilated roofing material is used?",
    visualNode: {
      img: pitchedRoof_barrier,
      label: "3",
      top: "105px",
      wrapperLeft: "0%",
      left: "290px",
      imgTop: "5%",
      width: "100%",
      nodeActive: false,
    },
    nodes: [
      {
        id: "pr_b_13_1",
        title: "Brick/concrete roof tiles",
        value: "Brick/concrete roof tiles",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_COLOR_OUTSIDE,
      },
      {
        id: "pr_b_13_2",
        title: "Metal roofing",
        value: "Metal roofing",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_COLOR_OUTSIDE,
      },
      {
        id: "pr_b_13_3",
        title: "Slate",
        value: "Slate",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_COLOR_OUTSIDE,
      },
      {
        id: "pr_b_13_4",
        title: "PV - in-roof system",
        value: "PV - in-roof system",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_COLOR_OUTSIDE,
      },
      {
        id: "pr_b_13_6",
        title: "Shingles",
        value: "Shingles",
        nextSection: SECTION_ID.PITCHED_ROOF_COVERING_COLOR_OUTSIDE,
      },
      {
        id: "pr_b_13_5",
        title: "Other",
        value: "Other",
        end: true,
      },
    ],
    warning: [
      {
        message: notSupportedMessage,
        rule: "pr_b_13_5",
      },
    ],
  },
];
