import { BUTTON_TYPE, DataSection, SECTION_ID } from "../../types";

import pitched_roof_vapor_barrier from "../../../assets/images/pitched_roof_vapor_barrier.svg";
import pitched_roof_vapor_barrier_png from "../../../assets/images/pitched_roof_vapor_barrier.png";

import pitched_roof_majcoat from "../../../assets/images/pitched_roof_majcoat.svg";
import pitched_roof_majcoat_png from "../../../assets/images/pitched_roof_majcoat.png";

import pitched_roof_subtop from "../../../assets/images/pitched_roof_subtop.svg";
import pitched_roof_subtop_png from "../../../assets/images/pitched_roof_subtop.png";

export const pitchedRoofBuildUp: DataSection[] = [
  {
    id: SECTION_ID.PITCHED_ROOF_BUILD_UP_OUTSIDE,
    divider: true,
    title: "Roof build-up:",
    nodes: [
      {
        id: "pr_open_4_1",
        title: "Roof insulation with vapor barrier",
        value: "Roof insulation with vapor barrier",
        buttonType: BUTTON_TYPE.VISUAL,
        icon: pitched_roof_vapor_barrier,
        iconPng: pitched_roof_vapor_barrier_png,
        // nextSection: SECTION_ID.PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS_BARRIER,
        disabled: true,
      },
      {
        id: "pr_open_4_2",
        title: "Roof insulation with Majcoat 200 (SOB)",
        value: "Roof insulation with Majcoat 200 (SOB)",
        buttonType: BUTTON_TYPE.VISUAL,
        icon: pitched_roof_majcoat,
        iconPng: pitched_roof_majcoat_png,
        nextSection: SECTION_ID.PITCHED_ROOF_INSULATION_BETWEEN_RAFTERS_OUTSIDE,
      },
      {
        id: "pr_open_4_3",
        title: "Roof insulation with sub-top variant",
        value: "Roof insulation with sub-top variant",
        buttonType: BUTTON_TYPE.VISUAL,
        icon: pitched_roof_subtop,
        iconPng: pitched_roof_subtop_png,
        disabled: true,
      },
    ],
  },
];
