import { BUTTON_TYPE } from "./types";

export const notSupportedMessage =
  "Currently we do not support this configuration. For more information please consult the PDF form.";

export const detectFreeMessage =
  "Detect-free under the following conditions.\n{link}SIGA tracks in various constructions{linkEnd}";

export const roofNotSupported = "Nachweisfrei unter Einhaltung der a.a.R.d.T..";
export const roofNotSupportedNewMessage =
  "Nachweisfrei unter Einhaltung der allgemein anerkannten Regeln der Technik (a.a.R.d.T.) und unter Beachtung des Mindestwärmeschutz.\n{link}SIGA-Bahnen in verschiedenen Konstruktionen{linkEnd}";

export const customPdfForm = {
  en: "KM13825_SIGA_Bahnen_verschiedenen_Konstruktionen_Support_App_de.pdf",
  de: "KM13825_SIGA_Bahnen_verschiedenen_Konstruktionen_Support_App_de.pdf",
  fr: "KM13825_SIGA_Bahnen_verschiedenen_Konstruktionen_Support_App_de.pdf",
};

export const pdfForm = {
  en: "SIGA_check_list_for_calculations_and_approvals_SKU-2010enDIV.pdf",
  de: "SIGA_Checkliste_für_Berechnungen_und_Freigaben_SKU-2010deDE.pdf",
  fr: "Checkliste_Berechnungen_fr_fr.pdf",
};

export const termsAndConditionsTitle = {
  default: `Allgemeine Geschäftsbedingungen Für Verträge über automatisierte
  Gewährleistungsschreiben (Deutschland)`,
  ch: `Allgemeine Geschäftsbedingungen Für Verträge über automatisierte
    Gewährleistungsschreiben (Schweiz)`,
};

export const pdfText1 = `<p>Guten Tag {{full_name}},<br><br/> besten Dank für Ihre Anfrage. Wir haben den von Ihnen
  beschriebenen Bauteilaufbau hinsichtlich der Verwendbarkeit von {{p}} beurteilt.</p>`;

export const pdfText2 = `<p>Bei dem von Ihnen unten beschriebenen Konstruktionsaufbau ist das
  feuchtetechnische Verhalten beim Einsatz der Dampfbremsbahn / Luftdichtheitsbahn {{p}} bei Einhaltung der beschriebenen
  Voraussetzungen als unkritisch zu bewerten.</p>`;

export const statementMsg =
  'Based on our calculation with the hygrothermal simulation program (EN 15026) "Delphin - The IBK institute in Dresden / Germany" we can adopt the following statement:\nIn any case, it must be ensured that the minimum thermal insulation requirements are met. This letter refers exclusively to the moisture-related functionality of the described construction. If the general terms and conditions are met,\n for this construction, the use of the vapor barrier is appropriate Majrex SIGA SIGA Cover AG gives you full warranty on all properties guaranteed in the instruction manual for SIGA Majrex.\nApply the SIGA products always according to the SIGA instruction manual. Professional planning, execution and control on-site is required. To ensure a proper hygrothermic function, an accurately assembled airtight-layer is essential.';

export const letterSignature = `Freundliche Grüsse\nIhr SIGA Team`;

export const languages = ["en", "de", "fr"];

export const angleNodes = [
  {
    id: "pr_11_1",
    title: "Alignment (worst situation)",
    buttonType: BUTTON_TYPE.CHECKBOX,
    value: "",
    childNodes: [
      {
        id: "pr_11_1_1",
        title: "North",
        value: "North",
        parent: "pr_11_1",
      },
      {
        id: "pr_11_1_2",
        title: "East",
        value: "East",
        parent: "pr_11_1",
      },
      {
        id: "pr_11_1_3",
        title: "South",
        value: "South",
        parent: "pr_11_1",
      },
      {
        id: "pr_11_1_4",
        title: "West",
        value: "West",
        parent: "pr_11_1",
      },
    ],
  },
  {
    id: "pr_11_2",
    title: "Roof pitch",
    buttonType: BUTTON_TYPE.CHECKBOX,
    value: "",
    disabled: true,
    childNodes: [
      {
        id: "pr_11_2_1",
        value: "5° - 9°",
        title: "5° - 9°",
        parent: "pr_11_2",
      },
      {
        id: "pr_11_2_2",
        value: "10° - 19°",
        title: "10° - 19°",
        parent: "pr_11_2",
      },
      {
        id: "pr_11_2_3",
        value: "20° - 29°",
        title: "20° - 29°",
        parent: "pr_11_2",
      },
      {
        id: "pr_11_2_4",
        value: "30° - 39°",
        title: "30° - 39°",
        parent: "pr_11_2",
      },
      {
        id: "pr_11_2_5",
        value: "40° - 49°",
        title: "40° - 49°",
        parent: "pr_11_2",
      },
      {
        id: "pr_11_2_6",
        value: "50° - 59°",
        title: "50° - 59°",
        parent: "pr_11_2",
      },
      {
        id: "pr_11_2_7",
        value: "60° - 69°",
        title: "60° - 69°",
        parent: "pr_11_2",
      },
      {
        id: "pr_11_2_8",
        value: "70° - 79°",
        title: "70° - 79°",
        parent: "pr_11_2",
      },
      {
        id: "pr_11_2_9",
        value: "80° - 89°",
        title: "80° - 89°",
        parent: "pr_11_2",
      },
    ],
  },
];
